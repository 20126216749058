<div class="menu_dialog">
  <div mat-dialog-title class="flex-end">
    <button
      id="menu_{{ itemIndex }}-close-btn"
      mat-icon-button
      mat-dialog-close
      class="close-btn"
      [title]="'COMMON.BUTTON.DIALOG.CLOSE' | translate"
      [attr.aria-label]="'COMMON.BUTTON.DIALOG.CLOSE' | translate"
    >
      <mat-icon>close_outlined</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <div class="menu__content">
      <pbb-notice
        *ngIf="!isErrorInline && data.errorKey"
        [title]="data.errorKey | translate"
        [inline]="isErrorInline"
        id="dialog_action_message"
        [type]="notice.ERROR"
        [showInContainer]="false"
      ></pbb-notice>
      <mat-icon class="confirm-icon" *ngIf="!option.showLogo">{{ option.icon }}</mat-icon>
      <div *ngIf="option.showLogo" class="logo-container">
        <img
          *ngIf="(option && option.logo) || option.imageSrc; else showBankIcon"
          class="logo"
          [src]="(option.logo | base64 | async) || option.imageSrc"
          pbbImgError="assets/images/bank.png"
          alt="Bank Logo"
        />
        <ng-template #showBankIcon>
          <mat-icon class="logo confirm-logo" aria-hidden="true">account_balance_outlined</mat-icon>
        </ng-template>
      </div>
      <h1 class="confirm-title mb-8" id="menu_{{ option.action | lowercase }}_title">
        {{ getTranslation(option, 'confirmTitle') }}
      </h1>
      <span class="confirm-subtitle mb-24" id="menu_{{ option.action | lowercase }}_info">
        {{ getTranslation(option, 'confirmSubtitle') }}
      </span>
      <pbb-notice
        *ngIf="isErrorInline && dialogMessageKey"
        [inline]="isErrorInline"
        [title]="
          dialogMessageKey
            | translate : (isSuccess ? option.dialogSuccessMessageParams : option.dialogErrorMessageParams)
        "
        id="dialog_action_message"
        [type]="isSuccess ? notice.INFO : notice.ERROR"
        [showInContainer]="false"
      ></pbb-notice>
      <button
        class="menu_content__unlink-confirm-btn"
        *ngIf="!!option.confirmButton"
        mat-flat-button
        cdkFocusInitial
        (click)="confirm(option)"
        [color]="option.confirmColor || 'primary'"
        id="menu_{{ option.action | lowercase }}_confirm-btn"
        [disabled]="isSuccess"
      >
        {{ getTranslation(option, 'confirmButton') }}
      </button>
      <button
        *ngIf="!!option.confirmCancel"
        mat-stroked-button
        mat-dialog-close
        class="btn-stroked-no-border text-underlined"
        [color]="option.confirmCancelColor"
        id="menu_{{ option.action | lowercase }}_cancel-btn"
      >
        {{ isSuccess ? ('COMMON.BUTTON.CLOSE' | translate) : getTranslation(option, 'confirmCancel') }}
      </button>
    </div>
  </div>
</div>
