import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeOf',
  standalone: true,
})
export class TypeOfPipe implements PipeTransform {
  transform(
    value: unknown,
    ...args: unknown[]
  ): 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function' {
    return typeof value;
  }
}
