import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivateFn } from '@angular/router';
import { LeaveOrStayComponent } from '@checkout/shared/dialogs/leave-or-stay/leave-or-stay.component';
import { partialGrayedScreenModalConfig } from '@core/constants/modal.config';
import { FormStateService } from '@core/services/form-state.service';
import { map, of } from 'rxjs';
import * as Util from '@core/utils';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

export const FormCanDeactivateGuard: CanDeactivateFn<any> = () => {
  const formStateService = inject(FormStateService);
  const dialog = inject(MatDialog);
  const bottomSheet = inject(MatBottomSheet);
  if (formStateService.hasChange) {
    if (Util.isMobile()) {
      return bottomSheet
        .open(LeaveOrStayComponent, {
          panelClass: 'content_main-drawer',
          autoFocus: false,
          data: {
            subtitleKey: 'COMMON.UNSAVED_FORM.PROMPT.SUBTITLE',
            isMobile: false,
          },
        })
        .afterDismissed()
        .pipe(map((res) => res === true));
    } else {
      return dialog
        .open(LeaveOrStayComponent, {
          ...partialGrayedScreenModalConfig,
          autoFocus: false,
          data: {
            subtitleKey: 'COMMON.UNSAVED_FORM.PROMPT.SUBTITLE',
            isMobile: false,
          },
        })
        .afterClosed()
        .pipe(map((res) => res === true));
    }
  }
  return of(true);
};
