<div class="content_main-drawer-container">
  <ng-container *ngIf="currentDisplay === 'menu'">
    <button
      mat-menu-item
      *ngFor="let option of options"
      (click)="click(option)"
      id="menu_{{ option.action | lowercase }}-btn"
      [attr.aria-label]="option.label | translate"
      [disabled]="
        (option.disabled | typeOf) === 'function' ? option && $any(option?.disabled)(item, itemIndex) : option.disabled
      "
    >
      <mat-icon class="mr-12">{{ option.icon }}</mat-icon>
      <span class="mat-body-2">
        {{ option.label | translate }}
      </span>
    </button>
  </ng-container>

  <ng-container *ngFor="let option of options">
    <ng-container *ngIf="currentDisplay === option.action">
      <pbb-notice
        class="mt-8"
        *ngIf="!isErrorInline && data.errorKey"
        [title]="data.errorKey | translate"
        [inline]="isErrorInline"
        id="dialog_action_message"
        [type]="notice.ERROR"
        [showInContainer]="false"
      ></pbb-notice>
      <mat-icon class="confirm-icon" *ngIf="!option.showLogo">{{ option.icon }}</mat-icon>
      <div *ngIf="option.showLogo" class="logo-container">
        <img
          *ngIf="(option && option.logo) || option.imageSrc; else showBankIcon"
          class="logo"
          [src]="(option.logo | base64 | async) || option.imageSrc"
          pbbImgError="assets/images/bank.png"
          alt="Bank Logo"
        />
        <ng-template #showBankIcon>
          <mat-icon class="logo confirm-logo" aria-hidden="true">account_balance_outlined</mat-icon>
        </ng-template>
      </div>
      <h1 class="confirm-title mb-8" id="menu_{{ option.action | lowercase }}_title">
        {{ getTranslation(option, 'confirmTitle') }}
      </h1>
      <span class="confirm-subtitle mb-24" id="menu_{{ option.action | lowercase }}_info">
        {{ getTranslation(option, 'confirmSubtitle') }}
      </span>
      <pbb-notice
        *ngIf="isErrorInline && dialogMessageKey"
        [inline]="isErrorInline"
        [title]="
          dialogMessageKey
            | translate : (isSuccess ? option.dialogSuccessMessageParams : option.dialogErrorMessageParams)
        "
        id="dialog_action_message"
        [type]="isSuccess ? notice.INFO : notice.ERROR"
        [showInContainer]="false"
      ></pbb-notice>
      <button
        class="menu_content__unlink-confirm-btn"
        *ngIf="!!option.confirmButton"
        [disabled]="isSuccess"
        (click)="confirm(option)"
        mat-flat-button
        [color]="option.confirmColor || 'primary'"
        id="menu_{{ option.action | lowercase }}_confirm-btn"
      >
        {{ getTranslation(option, 'confirmButton') }}
      </button>
      <button
        (click)="backToMenu()"
        *ngIf="!!option.confirmCancel"
        mat-stroked-button
        class="btn-stroked-no-border text-underlined"
        [color]="option.confirmCancelColor"
        id="menu_{{ option.action | lowercase }}_cancel-btn"
      >
        {{ isSuccess ? ('COMMON.BUTTON.CLOSE' | translate) : getTranslation(option, 'confirmCancel') }}
      </button>
    </ng-container>
  </ng-container>
</div>
