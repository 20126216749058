<div class="default-container">
  <pbb-notice
    title="{{ 'COMMON.EDIT_ADDRESS.YOUR_DEFAULT_ADDRESS' | translate }}"
    id="address-form-default_notice"
    [inline]="true"
    *ngIf="isDefaultAddress"
    [showInContainer]="false"
  ></pbb-notice>
</div>

<form *ngIf="addressForm" [formGroup]="addressForm" (ngSubmit)="submit()">
  <!-- full name -->
  <mat-form-field floatLabel="always" appearance="fill">
    <mat-label for="address-form-recipient_name">
      {{ 'COMMON.EDIT_ADDRESS.RECIPIENT_NAME1' | translate }}
    </mat-label>
    <input
      id="address-form-recipient_name"
      type="text"
      [attr.aria-describedby]="'pbb-form-recipient_name-error'"
      matInput
      maxlength="100"
      formControlName="recipient_name"
    />
    <mat-error
      pbb-form-field-error
      role="alert"
      label="recipient_name"
      [pbbFormControl]="getControl('recipient_name')"
    ></mat-error>
  </mat-form-field>

  <!-- street address -->
  <mat-form-field floatLabel="always" appearance="fill">
    <mat-label id="address-form-street_name-label">
      {{ 'COMMON.EDIT_ADDRESS.STREET_ADDRESS' | translate }}
      {{ (showRuralAddress ? 'COMMON.OPTIONAL' : '') | translate }}
    </mat-label>

    <button
      mat-stroked-button
      id="address-form-street-po-toggle"
      class="btn-address-toggle"
      disableRipple="true"
      type="button"
      color="primary"
      (click)="toggleRural()"
    >
      <span class="mat-caption">{{
        (showRuralAddress ? 'COMMON.EDIT_ADDRESS.OR_STREET_ADDRESS' : 'COMMON.EDIT_ADDRESS.OR_PO_BOX') | translate
      }}</span>
    </button>

    <input id="address-form-street_name" type="text" matInput maxlength="100" formControlName="street_name" />
    <mat-error
      pbb-form-field-error
      role="alert"
      label="street_name"
      [pbbFormControl]="getControl('street_name')"
    ></mat-error>
  </mat-form-field>

  <!-- rural PO BOX -->
  <mat-form-field floatLabel="always" appearance="fill" *ngIf="showRuralAddress">
    <mat-label id="address-form-rural_postal_address-label">
      {{ 'COMMON.EDIT_ADDRESS.RURAL_ADDRESS' | translate }}
    </mat-label>

    <input
      id="address-form-rural_postal_address"
      type="text"
      matInput
      maxlength="100"
      formControlName="rural_postal_address"
    />
    <mat-error
      pbb-form-field-error
      role="alert"
      label="rural_postal_address"
      [pbbFormControl]="getControl('rural_postal_address')"
    >
    </mat-error>
  </mat-form-field>

  <!-- apartment -->
  <mat-form-field floatLabel="always" appearance="fill">
    <mat-label id="address-form-apartment-label">
      {{ 'COMMON.EDIT_ADDRESS.APARTMENT' | translate }}
    </mat-label>
    <input id="address-form-apartment" type="text" matInput maxlength="20" formControlName="apartment" />
    <mat-error
      pbb-form-field-error
      role="alert"
      label="apartment"
      [pbbFormControl]="getControl('apartment')"
    ></mat-error>
  </mat-form-field>

  <!-- postal code -->
  <mat-form-field floatLabel="always" appearance="fill">
    <mat-label id="address-form-postal_code-label">
      {{ 'COMMON.EDIT_ADDRESS.POSTAL_CODE' | translate }}
    </mat-label>
    <input
      id="address-form-postal_code"
      type="text"
      matInput
      maxlength="40"
      formControlName="postal_code"
      mask="AAA AAA||S0S 0S0"
      [dropSpecialCharacters]="true"
      [validation]="false"
    />
    <mat-error
      pbb-form-field-error
      role="alert"
      label="postal_code"
      [pbbFormControl]="getControl('postal_code')"
    ></mat-error>
  </mat-form-field>

  <!-- city -->
  <mat-form-field floatLabel="always" appearance="fill">
    <mat-label id="address-form-city-label">
      {{ 'COMMON.EDIT_ADDRESS.CITY' | translate }}
    </mat-label>
    <input id="address-form-city" type="text" matInput maxlength="50" formControlName="city" />
    <mat-error pbb-form-field-error role="alert" label="city" [pbbFormControl]="getControl('city')"></mat-error>
  </mat-form-field>

  <!-- province -->
  <mat-form-field floatLabel="always" appearance="fill">
    <mat-label id="address-form-province-label">
      {{ 'COMMON.EDIT_ADDRESS.PROVINCE' | translate }}
    </mat-label>
    <input id="address-form-province" type="text" matInput maxlength="20" formControlName="province" />
    <mat-error pbb-form-field-error role="alert" label="province" [pbbFormControl]="getControl('province')"></mat-error>
  </mat-form-field>

  <!-- country -->
  <mat-form-field floatLabel="always" appearance="fill">
    <mat-label id="address-form-country-label">
      {{ 'COMMON.EDIT_ADDRESS.COUNTRY' | translate }}
    </mat-label>
    <input id="address-form-country" type="text" matInput formControlName="country" />
    <mat-error pbb-form-field-error role="alert" label="country" [pbbFormControl]="getControl('country')"></mat-error>
  </mat-form-field>

  <!-- address_line1 -->
  <mat-form-field floatLabel="always" appearance="fill">
    <mat-label id="address-form-note-label">
      {{ 'COMMON.EDIT_ADDRESS.NOTE' | translate }}
    </mat-label>
    <input id="address-form-note" type="text" matInput formControlName="address_line1" />
  </mat-form-field>

  <button
    id="address-form-continue"
    type="submit"
    class="address-form__checkout-continue mt-48"
    [disabled]="addressForm.invalid || isLoading"
    mat-flat-button
    color="primary"
  >
    {{ (this.address ? 'COMMON.BUTTON.SAVE' : 'COMMON.EDIT_ADDRESS.BUTTON.ADD_ADDRESS') | translate }}
  </button>
</form>
