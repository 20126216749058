import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { NOTICE_TYPE } from '@core/constants';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { UntilDestroy } from '@core/classes/untilDestroy.class';
import { isMobile } from '@core/utils';
import { Subscription, take, takeUntil } from 'rxjs';
import { PbbNoticeComponent } from '../pbb-notice/pbb-notice.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor, LowerCasePipe, AsyncPipe } from '@angular/common';
import { PbbImgErrorDirective } from '@core/directives/pbb-img-error.directive';
import { Base64Pipe } from '@core/pipes/base64.pipe';
import { TypeOfPipe } from '@core/pipes/type-of.pipe';

export interface MenuAction {
  action: string;
  item: any;
  itemRef?: string;
  itemIndex?: number;
}

export interface MenuOption {
  action: string;
  label: string;
  icon: string;
  logo?: string;
  color?: string;
  showConfirm: boolean;
  showLogo?: boolean;
  imageSrc?: string;
  confirmTitle?: string | Array<any> | ((item: any, index: number) => string);
  confirmSubtitle?: string | Array<any> | ((item: any, index: number) => string);
  confirmColor?: string;
  confirmButton?: string | Array<any>;
  confirmCancel?: string | Array<any>;
  confirmCancelColor?: string;
  disabled?: ((item: any, index: number) => boolean) | boolean;
  clicked?: (item: any, index: number) => void;
  closeOnSuccess?: boolean;
  dialogSuccessMessageParams?: any;
  dialogErrorMessageParams?: any;
}

@Component({
  selector: 'pbb-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatButtonModule, MatMenuModule, MatIconModule, NgFor, TypeOfPipe, TranslateModule, LowerCasePipe],
})
export class MenuComponent extends UntilDestroy implements OnDestroy {
  @Input() item!: any;
  @Input() itemRef?: string;
  @Input() itemIndex!: number;
  @Input() options: Array<MenuOption> = [];

  @Output() menuAction: EventEmitter<MenuAction> = new EventEmitter();

  isMobile = isMobile();
  @Input() menuAriaLabel?: string = '';
  @Input() idLabel!: string;
  @Input() disabled = false;
  dialogMessageKey!: string;
  isSuccess = false;
  constructor(private readonly _bottomSheet: MatBottomSheet, public dialog: MatDialog) {
    super();
  }

  openMobileMenu(): void {
    const btmSheetRef = this._bottomSheet.open(MenuMobileComponent, {
      data: {
        options: this.options,
        item: this.item,
        itemRef: this.itemRef,
        index: this.itemIndex,
      },
      panelClass: 'content_main-drawer',
      backdropClass: 'content_main-bottom-sheet',
    });

    btmSheetRef.instance.onConfirm$.pipe(takeUntil(this.destroy$)).subscribe((option) => {
      this.menuAction.emit({
        action: option.action,
        item: this.item,
        itemIndex: this.itemIndex,
        itemRef: this.itemRef,
      });
      if (!option.showConfirm) {
        btmSheetRef.dismiss();
      }
    });
  }

  desktopOptionClicked(option: MenuOption): void {
    if (option.clicked) {
      option.clicked(this.item, this.itemIndex);
    }
    this.menuAction.emit({
      action: option.action,
      item: this.item,
      itemIndex: this.itemIndex,
      itemRef: this.itemRef,
    });
  }

  openDesktopMenu(option: MenuOption): void {
    if (option.showConfirm) {
      const dialogRef = this.dialog.open(MenuDesktopConfirmComponent, {
        data: {
          option: option,
          item: this.item,
          itemRef: this.itemRef,
          index: this.itemIndex,
        },
        closeOnNavigation: true,
        autoFocus: false,
        width: '416px',
        panelClass: 'content_overlay-medium',
        backdropClass: 'content_overlay-dialog',
      });
      dialogRef.componentInstance.onConfirm$.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.menuAction.emit({
          action: option.action,
          item: this.item,
          itemIndex: this.itemIndex,
          itemRef: this.itemRef,
        });
      });
    } else {
      this.desktopOptionClicked(option);
    }
  }
}

@Component({
  selector: 'pbb-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatMenuModule,
    MatIconModule,
    PbbNoticeComponent,
    PbbImgErrorDirective,
    MatButtonModule,
    Base64Pipe,
    TypeOfPipe,
    TranslateModule,
    AsyncPipe,
    LowerCasePipe,
  ],
})
export class MenuMobileComponent implements OnDestroy {
  options: Array<MenuOption> = [];
  selectedOption!: MenuOption;
  item: any;
  itemIndex: number;
  itemRef: string;
  currentDisplay = 'menu';
  Array = Array;
  notice = NOTICE_TYPE;
  onConfirm$ = new EventEmitter<MenuOption>();
  onClose$ = new EventEmitter<boolean>();
  dialogMessageKey!: string;
  dialogMessageSubs!: Subscription;
  isSuccess: boolean = false;
  isErrorInline = true;
  constructor(
    private readonly translateService: TranslateService,
    private readonly _bottomSheetRef: MatBottomSheetRef<MenuMobileComponent>,
    private readonly cdr: ChangeDetectorRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.options = data.options;
    this.item = data.item;
    this.itemIndex = data.index;
    this.itemRef = data.itemRef;
    this.isErrorInline = data.isErrorInline ?? true;
    this.currentDisplay = data.currentDisplay ?? 'menu';
  }

  ngOnDestroy(): void {
    this.dialogMessageSubs?.unsubscribe();
  }
  getTranslation(option: any, property: string): string {
    const translateObject = option[property];
    if (typeof translateObject === 'string') {
      return this.translateService.instant(translateObject);
    } else if (Array.isArray(option[property])) {
      return this.translateService.instant(translateObject[0], translateObject[1]);
    } else if (typeof translateObject === 'function') {
      return translateObject(this.item, this.itemIndex);
    }
    return '';
  }

  backToMenu(): void {
    this.currentDisplay = 'menu';
    this._bottomSheetRef.dismiss();
  }

  confirm(option: MenuOption): void {
    if (option.clicked) {
      option.clicked(this.item, this.itemIndex);
    }
    this.selectedOption = option;
    if (option.closeOnSuccess) {
      this._bottomSheetRef.dismiss();
    }
    this.onConfirm$.emit(option);
  }

  click(option: MenuOption): void {
    if (option.showConfirm) {
      this.currentDisplay = option.action;
    } else {
      this.confirm(option);
    }
  }
}

@Component({
  selector: 'pbb-menu-desktop-confirm',
  templateUrl: './menu-desktop-confirm.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    NgIf,
    PbbNoticeComponent,
    PbbImgErrorDirective,
    Base64Pipe,
    TranslateModule,
    AsyncPipe,
    LowerCasePipe,
  ],
})
export class MenuDesktopConfirmComponent implements OnDestroy {
  option: MenuOption;
  item: any;
  itemIndex: number;
  itemRef: string;
  dialogMessageKey!: string;
  Array = Array;
  notice = NOTICE_TYPE;
  onConfirm$ = new EventEmitter<MenuOption>();
  onClose$ = new EventEmitter<boolean>();
  dialogMessageSubs!: Subscription;
  isSuccess: boolean = false;
  loading: boolean = false;
  isErrorInline = true;
  constructor(
    private readonly translateService: TranslateService,
    public dialogRef: MatDialogRef<MenuDesktopConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.option = data.option;
    this.item = data.item;
    this.itemIndex = data.itemIndex;
    this.itemRef = data.itemRef;
    this.isErrorInline = data.isErrorInline ?? true;
  }
  ngOnDestroy(): void {
    this.dialogMessageSubs?.unsubscribe();
  }

  getTranslation(option: any, property: string): string {
    const translateObject = option[property];
    if (typeof translateObject === 'string') {
      return this.translateService.instant(translateObject);
    } else if (Array.isArray(option[property])) {
      return this.translateService.instant(translateObject[0], translateObject[1]);
    } else if (typeof translateObject === 'function') {
      return translateObject(this.item, this.itemIndex);
    }
    return '';
  }

  click(): void {
    if (this.option.clicked) {
      this.option.clicked(this.item, this.itemIndex);
    }
  }

  confirm(action: MenuOption) {
    if (this.option.closeOnSuccess) {
      this.dialogRef.close();
    }
    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        this.onConfirm$.emit(action);
      });
  }
}
