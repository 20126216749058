import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FinancialInstitution } from '@store/core/core.interface';
import { TranslateModule } from '@ngx-translate/core';
import { IdFormatPipe } from '@core/pipes/id-format.pipe';
import { Base64Pipe } from '@core/pipes/base64.pipe';
import { PbbImgErrorDirective } from '@core/directives/pbb-img-error.directive';
import { NgClass, NgIf, AsyncPipe, UpperCasePipe } from '@angular/common';

@Component({
  selector: 'pbb-address-suggestion',
  templateUrl: './address-suggestion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, PbbImgErrorDirective, NgIf, Base64Pipe, IdFormatPipe, TranslateModule, AsyncPipe, UpperCasePipe],
})
export class AddressSuggestionComponent {
  @Input() financialInstitution?: FinancialInstitution;
  @Input() hideSuggestion? = false;
}
