import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { IUserProfile } from '@store/common/user.interface';
import { MenuAction, MenuComponent } from '../menu/menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { IdFormatPipe } from '@core/pipes/id-format.pipe';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { AddressSuggestionComponent } from '../address-suggestion/address-suggestion.component';
import { NgFor, NgIf } from '@angular/common';
import { PbbSvgIconComponent } from '../pbb-svg-icon/pbb-svg-icon.component';
export const ACTION = {
  UNLINK: 'unlink',
  UNLINK_LAST: 'unlink_last',
  DEFAULT: 'default',
};
@Component({
  selector: 'pbb-payment-method-list',
  templateUrl: './payment-method-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    AddressSuggestionComponent,
    MenuComponent,
    NgIf,
    MatIconModule,
    MatListModule,
    MatChipsModule,
    IdFormatPipe,
    TranslateModule,
    PbbSvgIconComponent
  ],
})
export class PaymentMethodListComponent {
  @Input() userProfile!: IUserProfile | undefined;
  @Output() menuAction: EventEmitter<MenuAction> = new EventEmitter();
  Action = ACTION;

  setPaymentMethod(menuAction: MenuAction, linkedFiRef?: string) {
    this.menuAction.emit({
      ...menuAction,
      item: { paymentMethodRef: menuAction.item.fi_account_ref, linkedFiRef, ...menuAction.item },
    });
  }
}
