<div
  id="{{ financialInstitution?.display_name | idFormat }}_bank_suggestion }}"
  class="flex flex-row-middle"
  [ngClass]="!hideSuggestion ? 'mt-16 mb-16' : ''"
>
  <img
    class="logo"
    [src]="financialInstitution?.logo | base64 | async"
    class="icon-mdl mr-12"
    pbbImgError="assets/images/bank.png"
    [attr.alt]="'ARIA_LABEL.BANK_LOGO' | translate : { bankName: financialInstitution?.display_name }"
    id="{{ financialInstitution?.display_name | idFormat }}_logo }}"
  />
  <span class="mat-caption text-desc">
    <ng-container *ngIf="!hideSuggestion">{{ 'COMMON.VIEW_ADDRESS.SUGGESTION' | translate | uppercase }}</ng-container>

    {{ (financialInstitution ? financialInstitution.display_name : 'No Name Bank') | uppercase }}
  </span>
</div>
