import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'idFormat',
  standalone: true,
})
export class IdFormatPipe implements PipeTransform {
  transform(value: any, splitBy: string = ' ', joinWith: string = '_', toLowerCase: boolean = true): string {
    if (!value) {
      return '';
    }
    const formattedValue = value.split(splitBy).join(joinWith);
    if (toLowerCase) {
      return formattedValue.toLowerCase();
    }
    return formattedValue.toUpperCase();
  }
}
